<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-2">
        [A] acid is the best choice because it is the [B]. The presence of [C] can cause cations to
        precipitate, or it can oxidize to [D] in the presence of a strong oxidizing agent. This may
        warrant using [E] acid.
      </p>
      <p class="mb-2">
        [F] acid should be used as a last resort because it is a strong [G]. Most acids with a
        concentration greater than [H] and a pH less than [I] are corrosive and can cause damage.
      </p>
      <p class="mb-2">
        When exposed to acid, proteins in the skin are damaged and form [J] which accumulates to [K]
        damage to underlying tissues.
      </p>
      <p class="mb-2">
        Oxidizing agents are also corrosive. Which of the following is not an oxidizing agent? [L]
      </p>

      <p v-for="(letter, index) in letterOptions" :key="letter.inputName">
        {{ letter.text }}
        <v-select
          v-model="inputs[letter.inputName]"
          style="display: inline-block; max-width: 250px"
          class="my-1"
          :items="allQuestionOptions[index]"
          item-text="text"
          item-value="value"
          label="Select an option:"
        >
          <template #item="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
          <template #selection="{item}">
            <stemble-latex class="no-text-transform" :content="item.text" />
          </template>
        </v-select>
      </p>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';
import STextarea from '@/common/components/STextarea.vue';

export default {
  name: 'ChemUCI1LC_Q4S1_Q10',
  components: {StembleLatex, STextarea},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
        input5: null,
        input6: null,
        input7: null,
        input8: null,
        input9: null,
        input10: null,
        input11: null,
        input12: null,
      },
      allQuestionOptions: [
        [
          {text: 'nitric', value: 'nitric'},
          {text: 'sulfuric', value: 'sulfuric'},
          {text: 'hydrochloric', value: 'hydrochloric'},
        ],
        [
          {text: 'cheapest', value: 'cheapest'},
          {text: 'least corrosive', value: 'leastCorrosive'},
          {text: 'most reactive', value: 'mostReactive'},
          {text: 'easiest to discard', value: 'easiest'},
        ],
        [
          {text: 'chloride ions', value: 'chloride'},
          {text: 'sulfate ions', value: 'sulfate'},
          {text: 'nitrate ions', value: 'nitrate'},
          {text: 'hydronium ions', value: 'hydronium'},
        ],
        [
          {text: 'chlorine gas', value: 'chlorine'},
          {text: 'elemental sulfur solid', value: 'sulfurSolid'},
          {text: 'sulfur dioxide gas', value: 'sulfurDioxide'},
          {text: 'nitrous oxide gas', value: 'nitrousOxide'},
          {text: 'nitrogen gas', value: 'nitrogen'},
          {text: 'oxygen gas', value: 'oxygen'},
        ],
        [
          {text: 'nitric', value: 'nitric'},
          {text: 'sulfuric', value: 'sulfuric'},
          {text: 'hydrochloric', value: 'hydrochloric'},
        ],
        [
          {text: 'nitric', value: 'nitric'},
          {text: 'sulfuric', value: 'sulfuric'},
          {text: 'hydrochloric', value: 'hydrochloric'},
        ],
        [
          {text: 'oxidizing agent', value: 'oxidizingAgent'},
          {text: 'acid', value: 'acid'},
          {text: 'decomposer', value: 'decomposer'},
          {text: 'buffer', value: 'buffer'},
        ],
        [
          {text: '1 M', value: '1'},
          {text: '2 M', value: '2'},
          {text: '0.5 M', value: '0.5'},
          {text: '3 M', value: '3'},
          {text: '6 M', value: '6'},
        ],
        [
          {text: '0', value: '0'},
          {text: '1', value: '1'},
          {text: '2', value: '2'},
          {text: '3', value: '3'},
          {text: '4', value: '4'},
          {text: '5', value: '5'},
        ],
        [
          {text: 'coagulum', value: 'coagulum'},
          {text: 'scar tissue', value: 'scarTissue'},
          {text: 'acid reservoirs', value: 'acid'},
          {text: 'soluble skin tissue', value: 'skinTissue'},
        ],
        [
          {text: 'prevent', value: 'prevent'},
          {text: 'further', value: 'further'},
          {text: 'accelerate', value: 'accelerate'},
        ],
        [
          {text: 'ammonium hydroxide', value: 'ammoniumHydroxide'},
          {text: 'nitric acid', value: 'nitricAcid'},
          {text: 'hydrogen peroxide', value: 'hydrogenPeroxide'},
          {text: 'potassium permanganate', value: 'potassiumPermanganate'},
          {text: 'most nitrate salts', value: 'nitrateSalts'},
        ],
      ],
      letterOptions: [
        {text: '[A]: ', inputName: 'input1'},
        {text: '[B]: ', inputName: 'input2'},
        {text: '[C]: ', inputName: 'input3'},
        {text: '[D]: ', inputName: 'input4'},
        {text: '[E]: ', inputName: 'input5'},
        {text: '[F]: ', inputName: 'input6'},
        {text: '[G]: ', inputName: 'input7'},
        {text: '[H]: ', inputName: 'input8'},
        {text: '[I]: ', inputName: 'input9'},
        {text: '[J]: ', inputName: 'input10'},
        {text: '[K]: ', inputName: 'input11'},
        {text: '[L]: ', inputName: 'input12'},
      ],
    };
  },
};
</script>
